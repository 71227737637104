<mat-card>
    <mat-card-header>
      <mat-card-title>Imprint</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            Jannis Brinker
            <br>
            Bertha-von-Suttner-Straße 4
            <br>
            15806 Zossen
            <br>
            Germany
            <br>
            <br>
            E-Mail: <a href="mailto:contact@liumex.com">contact&#64;liumex.com</a>
            <br>
            <br>
            VAT No.: DE368506992
        </p>
        <mat-card-title>Liability for Content</mat-card-title>
        <p>
            The contents of our website have been created with the greatest possible care. We can't guarantee the contents accuracy, completeness or topicality. According to Section 7 paragraph 1 of the TMG (Telemediengesetz - German Telemedia Act) we as service provider are liable for our content on these pages by general laws. According to sections 8 to 10 of the TMG, we as service provider are not obliged to monitor external information transmitted or stored or investigate circumstances pointing to illegal activity. Obligations to remove or block the use of information under general laws remain unaffected. A liability in this regard is only possible from the moment of knowledge of a specific infringement. Upon notification of violations we will remove the content immediately.
        </p>
        <mat-card-title>Liability for Links</mat-card-title>
        <p>
            Our website contain links to external websites over whose contents we have no control. We can't accept any liability for these external contents. The respective provider or operator of the website is always responsible for the contents of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents weren't identified at the time of linking. Permanent monitoring of the contents of the linked pages isn't reasonable without specific indications of a violation. Upon notification of violations we will remove the links immediately.
        </p>
        <mat-card-title>Copyright</mat-card-title>
        <p>
            The contents and works on these pages created by the site operator are subject to German copyright law. The duplication, processing, distribution and any kind of utilization outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use. In so far as the contents on this site weren't created by the operator the copyrights of third parties are respected. Should you become aware of a copyright infringement please inform us accordingly. Upon notification of violations we will remove the contents immediately.
        </p>
        <p>Last Modified: March 19, 2025</p>
    </mat-card-content>
</mat-card>